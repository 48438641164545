import React from "react";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="footer">
      <h1 className="text-center">Best Deals and Offers @ Harmaal Deals </h1>
      <p className="text-center mt-3">
        <Link to="/">Offers</Link>|<Link to="/">Deals</Link>|
        <Link to="/disclosure">More</Link><br></br><br></br>
        <a href="https://www.infinitigrowit.solutions" target="_blank" className="text-center">Designed|Developed|Maintened by InfinitiGrowIT Solutions</a>

      </p>
    </div>
  );
};

export default Footer;
