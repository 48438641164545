export const Prices = [
  {
    _id: 0,
    name: "₹0 to 100",
    array: [0, 100],
  },
  {
    _id: 1,
    name: "₹101 to 200",
    array: [101, 200],
  },
  {
    _id: 2,
    name: "₹201 to 300",
    array: [201, 300],
  },
  {
    _id: 3,
    name: "₹301 to 500",
    array: [301, 500],
  },
  {
    _id: 4,
    name: "₹501 to 800",
    array: [501, 800],
  },
  {
    _id: 4,
    name: "Above",
    array: [800, 9999],
  },
];
