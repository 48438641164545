import React from "react";
import Layout from "./../components/Layout/Layout";

const About = () => {
  return (
    <Layout>
      <div className="row container disclosure">
        <h1>Affiliate Disclosure</h1>
        <p>
          For any questions about this site, please contact
          anasdeshmukh98@gmail.com. harmaaldeals.shop is a participant in the Amazon
          Services LLC Associates Program & other, an affiliate advertising
          program designed to provide a means for sites to earn advertising fees
          by advertising, As an Amazon Associate/another affiliate like
          (Flipkart) we earn from qualifying purchases harmaaldeals contains
          links to other websites. All external links contain content that is
          not endorsed or controlled by harmaaldeals. All of the deals and
          offers that are listed on harmaaldeals are true to the best of our
          knowledge, and we cannot be held responsible for accuracy for them or
          on external websites. All information that you submit to other
          websites is done at your own risk. Please be careful when giving out
          your personal information online. harmaaldeals encourages you to read
          the Privacy Policy or their Terms & Conditions before submitting any
          information. harmaaldeals is not an e-commerce platform, so all the
          deals/offers/free samples/products or services that you purchase via
          harmaaldeals links from the other websites are not provided by
          harmaaldeals. We are just a gateway to other sites and not the seller
          or supplier. harmaaldeals does not guarantee
          accuracy/quality/quantity/shipping/transactions etc. of any of your
          purchased goods or services from other e-commerce websites and hence
          harmaaldeals deal cannot be held responsible for any deviation for the same.
          harmaaldeals.shop welcomes feedback and improvements in updating the
          information published and we are willing to correct any discrepancies
          if brought to our attention and found to be valid. We try our best to
          present non-conflicting of interest information. We take every
          necessary precaution before posting any content on the harmaaldeals deal but
          still if you have any problem with any deal or offer, feel free to
          mail us at contact anasdeshmukh98@gmail.com and we will try to resolve
          it as soon as possible if found to be relevant. Thank you for reading.
          We love to provide you with all the best offers and deals.
        </p>
      </div>
    </Layout>
  );
};

export default About;
