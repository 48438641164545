import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox, Radio } from "antd";
import { Prices } from "../components/Prices";
import { useCart } from "../context/cart";
import axios from "axios";
import toast from "react-hot-toast";
import Layout from "./../components/Layout/Layout";
import { AiOutlineReload } from "react-icons/ai";
import "../styles/Homepage.css";

const HomePage = () => {
  const navigate = useNavigate();
  const [cart, setCart] = useCart();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [checked, setChecked] = useState([]);
  const [radio, setRadio] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  //get all cat
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get("/api/v1/category/get-category");
      if (data?.success) {
        setCategories(data?.category);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCategory();
    getTotal();
  }, []);
  //get products
  const getAllProducts = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/api/v1/product/product-list/${page}`);
      setLoading(false);
      setProducts(data.products);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  //getTOtal COunt
  const getTotal = async () => {
    try {
      const { data } = await axios.get("/api/v1/product/product-count");
      setTotal(data?.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (page === 1) return;
    loadMore();
  }, [page]);
  //load more
  const loadMore = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/api/v1/product/product-list/${page}`);
      setLoading(false);
      setProducts([...products, ...data?.products]);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // filter by cat
  const handleFilter = (value, id) => {
    let all = [...checked];
    if (value) {
      all.push(id);
    } else {
      all = all.filter((c) => c !== id);
    }
    setChecked(all);
  };
  useEffect(() => {
    if (!checked.length || !radio.length) getAllProducts();
  }, [checked.length, radio.length]);

  useEffect(() => {
    if (checked.length || radio.length) filterProduct();
  }, [checked, radio]);

  //get filterd product
  const filterProduct = async () => {
    try {
      const { data } = await axios.post("/api/v1/product/product-filters", {
        checked,
        radio,
      });
      setProducts(data?.products);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Layout title={"All Products - Best offers "}>
      <div className="container-fluid row mt-3 home-page">
        <div className="col-md-3 filters">
          <div className="d-flex">
            <div className="d-flex flex-column">
              <a
                href="https://chat.whatsapp.com/C7rDe2pO7TL0lUnmNpbT7z"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  className="btn btn-danger"
                  style={{ borderRadius: "10px", backgroundColor: " #075e54" }} // Adjust the radius value as needed
                >
                  Join WhatsApp
                </button>
              </a>
            </div>

            <div className="d-flex flex-column">
              <a
                href="https://t.me/+at5Odjsvv6FhZTU1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  className="btn btn-danger"
                  style={{ borderRadius: "10px", backgroundColor: "#0088CC" }} // Adjust the radius value as needed
                >
                  Join Telegram
                </button>
              </a>
            </div>
          </div>

          <div className="d-flex flex-column">
            <button
              className="btn btn-danger"
              style={{ borderRadius: "10px" }} // Adjust the radius value as needed
              onClick={() => window.location.reload()}
            >
              RESET FILTERS
            </button>
          </div>
          <h5 className="text-center">Filter By Category</h5>
          <div className="d-flex flex-wrap">
            {categories?.map((c) => (
              <Checkbox
                key={c._id}
                onChange={(e) => handleFilter(e.target.checked, c._id)}
              >
                {c.name}
              </Checkbox>
            ))}
          </div>
          {/* price filter */}
          <h5 className="text-center mt-2">Filter By Price</h5>
          <div className="d-flex flex-column">
            <Radio.Group
              onChange={(e) => setRadio(e.target.value)}
              className="radio-group"
            >
              {Prices?.map((p) => (
                <div key={p._id} className="radio-item">
                  <Radio value={p.array}>{p.name}</Radio>
                </div>
              ))}
            </Radio.Group>
          </div>
        </div>
        <div className="col-md-16">
          <h1 className="text-center">All Products</h1>
          <div className="d-flex flex-wrap cardd">
            {products?.map((p) => (
              <div
                className="card-container m-2"
                key={p._id}
                style={{ position: "relative" }}
              >
                {p.offerr && (
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      background: "#e74c3c",
                      color: "white",
                      padding: "1px 1px",
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      fontSize: "13px",
                      borderRadius: "50%", // Making the border radius round
                      zIndex: 999,
                    }}
                  >
                    {p.offerr} % Off
                  </div>
                )}

                <img
                  src={`/api/v1/product/product-photo/${p._id}`}
                  className="card-img-left"
                 
                  alt={p.name}
                />
                <div className="card-content">
                  <div
                    className="card-content"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <p className="card-title"  style={{
                 marginTop:"1.5vmax"
                  }}>{p.name}</p>
                    <p
                      className="card-time"
                      style={{
                        fontSize: "14px",
                        color: "#777",
                        position: "absolute",
                        top: "0",
                        right: "0",
                      }}
                    >
                      {getTimeDifference(p.createdAt)}
                    </p>
                  </div>
                  <div className="card-price">
                    <h5>
                    ₹{p.price}
                    </h5>
                  </div>
                  <p className="card-text">
                    <del>₹{p.description.substring(0, 60)}</del>
                  </p>
                  {p.brand && (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "0",
                        right: "0",
                        background: "#FF9933",
                        color: "white",
                        padding: "1px 2px",
                        width: "70px",
                        height: "27px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        fontSize: "16px", // Adjust font size as needed
                        // borderRadius: "4px", // Add border radius for better appearance
                        zIndex: 999, // Ensure the offer text appears above other content
                        fontFamily: "Playfair Display",
                      }}
                    >
                      {p.brand}
                    </div>
                  )}

                  <div className="card-name-price">
                    <button
                      className="btn btn ms-1"
                   
                      onClick={(e) => {
                        e.preventDefault();
                        // Use window.location.href to navigate to the external link
                        window.open(p.link, "_blank");
                      }}
                    >
                      Buy
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="m-2 p-3">
            {products && products.length < total && (
              <button
                className="btn loadmore"
                onClick={(e) => {
                  e.preventDefault();
                  setPage(page + 1);
                }}
              >
                {loading ? (
                  "Loading ..."
                ) : (
                  <>
                    {" "}
                    Loadmore <AiOutlineReload />
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      </div>

      {/* banner image */}
      <img
        src="/images/offerbanner.jpg"
        className="banner-img"
        alt="bannerimage"
        width={"100%"}
      />
      {/* banner image */}
    </Layout>
  );
};

// Function to calculate time difference
const getTimeDifference = (createdAt) => {
  const currentTime = new Date();
  const createdTime = new Date(createdAt);
  const difference = currentTime - createdTime;
  const minutes = Math.floor(difference / 1000 / 60);
  if (minutes < 60) {
    return `${minutes} min ago`;
  } else {
    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
      return `${hours} hr ago`;
    } else {
      const days = Math.floor(hours / 24);
      return `${days} day ago`;
    }
  }
};

export default HomePage;







// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { Checkbox, Radio } from "antd";
// import { Prices } from "../components/Prices";
// import { useCart } from "../context/cart";
// import axios from "axios";
// import toast from "react-hot-toast";
// import Layout from "./../components/Layout/Layout";
// import { AiOutlineReload } from "react-icons/ai";
// import "../styles/Homepage.css";

// const HomePage = () => {
//   const navigate = useNavigate();
//   const [cart, setCart] = useCart();
//   const [products, setProducts] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [checked, setChecked] = useState([]);
//   const [radio, setRadio] = useState([]);
//   const [total, setTotal] = useState(0);
//   const [page, setPage] = useState(1);
//   const [loading, setLoading] = useState(false);

//   //get all cat
//   const getAllCategory = async () => {
//     try {
//       const { data } = await axios.get("/api/v1/category/get-category");
//       if (data?.success) {
//         setCategories(data?.category);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     getAllCategory();
//     getTotal();
//   }, []);
//   //get products
//   const getAllProducts = async () => {
//     try {
//       setLoading(true);
//       const { data } = await axios.get(`/api/v1/product/product-list/${page}`);
//       setLoading(false);
//       setProducts(data.products);
//     } catch (error) {
//       setLoading(false);
//       console.log(error);
//     }
//   };

//   //getTOtal COunt
//   const getTotal = async () => {
//     try {
//       const { data } = await axios.get("/api/v1/product/product-count");
//       setTotal(data?.total);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     if (page === 1) return;
//     loadMore();
//   }, [page]);
//   //load more
//   const loadMore = async () => {
//     try {
//       setLoading(true);
//       const { data } = await axios.get(`/api/v1/product/product-list/${page}`);
//       setLoading(false);
//       setProducts([...products, ...data?.products]);
//     } catch (error) {
//       console.log(error);
//       setLoading(false);
//     }
//   };

//   // filter by cat
//   const handleFilter = (value, id) => {
//     let all = [...checked];
//     if (value) {
//       all.push(id);
//     } else {
//       all = all.filter((c) => c !== id);
//     }
//     setChecked(all);
//   };
//   useEffect(() => {
//     if (!checked.length || !radio.length) getAllProducts();
//   }, [checked.length, radio.length]);

//   useEffect(() => {
//     if (checked.length || radio.length) filterProduct();
//   }, [checked, radio]);

//   //get filterd product
//   const filterProduct = async () => {
//     try {
//       const { data } = await axios.post("/api/v1/product/product-filters", {
//         checked,
//         radio,
//       });
//       setProducts(data?.products);
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   return (
//     <Layout title={"All Products - Best offers "}>

//       <div className="container-fluid row mt-3 home-page">
//         <div className="col-md-3 filters">
//         <div className="d-flex flex-column">
//             <button
//               className="btn btn-danger"
//               onClick={() => window.location.reload()}
//             >
//               RESET FILTERS
//             </button>
//           </div>
//           <h5 className="text-center">Filter By Category</h5>
//           <div className="d-flex flex-wrap">
//             {categories?.map((c) => (
//               <Checkbox
//                 key={c._id}
//                 onChange={(e) => handleFilter(e.target.checked, c._id)}
//               >
//                 {c.name}
//               </Checkbox>
//             ))}
//           </div>
//           {/* price filter */}
//           <h5 className="text-center mt-2">Filter By Price</h5>
//           <div className="d-flex flex-column">
//             <Radio.Group
//               onChange={(e) => setRadio(e.target.value)}
//               className="radio-group"
//             >
//               {Prices?.map((p) => (
//                 <div key={p._id} className="radio-item">
//                   <Radio value={p.array}>{p.name}</Radio>
//                 </div>
//               ))}
//             </Radio.Group>
//           </div>
//         </div>
//         <div className="col-md-16 ">
//           <h1 className="text-center">All Products</h1>
//           <div className="d-flex flex-wrap">
//             {products?.map((p) => (
//               <div className="card-container m-2" key={p._id}>
//                 <img
//                   src={`/api/v1/product/product-photo/${p._id}`}
//                   className="card-img-left"
//                   alt={p.name}
//                 />
//                 <div className="card-content">
//                   <h5 className="card-title">{p.name}</h5>
//                   <div className="card-price">
//                     <h5>
//                       {p.price.toLocaleString("en-US", {
//                         style: "currency",
//                         currency: "USD",
//                       })}
//                     </h5>
//                   </div>
//                   <p className="card-text">
//                     {p.description.substring(0, 60)}...
//                   </p>
//                   <div className="card-name-price">
//                     {/* <button
//                       className="btn btn-info ms-1"
//                       onClick={() => navigate(`/product/${p.slug}`)}
//                     >
//                       More Details
//                     </button> */}
//                     <button
//                       className="btn btn-dark ms-1"
//                       onClick={(e) => {
//                         e.preventDefault();
//                         // Use window.location.href to navigate to the external link
//                         window.location.href = p.link;
//                       }}
//                     >
//                       Get Offer
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//           <div className="m-2 p-3">
//             {products && products.length < total && (
//               <button
//                 className="btn loadmore"
//                 onClick={(e) => {
//                   e.preventDefault();
//                   setPage(page + 1);
//                 }}
//               >
//                 {loading ? (
//                   "Loading ..."
//                 ) : (
//                   <>
//                     {" "}
//                     Loadmore <AiOutlineReload />
//                   </>
//                 )}
//               </button>
//             )}
//           </div>
//         </div>
//       </div>

//        {/* banner image */}
//        <img
//         src="/images/banner.png"
//         className="banner-img"
//         alt="bannerimage"
//         width={"100%"}
//       />
//       {/* banner image */}
//     </Layout>
//   );
// };

// export default HomePage;
